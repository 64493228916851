import React, { useState, useEffect } from 'react';
import { Button, DatePicker, message, Select, Form, Input } from 'antd';
import { useSelector, useDispatch } from 'react-redux';
import AddPartyModal from '../addPartyModal';
import supabase from '../../db';
import "../common.css"
import nextBillNumber from '../../Functions/nextBillNumber';
import useNextBillNumber from '../../Functions/nextBillNumber';

function InvoiceDetails({ current, steps, next, prev }) {
    const dispatch = useDispatch();
    const [form] = Form.useForm();
    const getNextBillNumber = useNextBillNumber();

    const [partyData, setPartyData] = useState({});
    const [billNum, setBillNum] = useState(null);
    const [selectedPartyCode, setSelectedPartyCode] = useState(null);
    const [partyModalVisible, setPartyModalVisible] = useState(false);

    const billNumber = useSelector(state => state.billNumber);
    const branch = useSelector(state => state.branch);
    const date = useSelector(state => state.date);
    const lorryNumber = useSelector(state => state.lorryNumber);
    const partyName = useSelector(state => state.partyName);
    const partyAddress = useSelector(state => state.partyAddress);
    const partyGstin = useSelector(state => state.partyGstin);
    const partyCode = useSelector(state => state.partyCode);

    useEffect(() => {
        const fetchBillNumber = async () => {
            if (!billNumber) {
                const nextBillNumber = await getNextBillNumber();
                setBillNum(nextBillNumber);
            } else {
                setBillNum(billNumber);
            }
        };

        fetchBillNumber();
    }, [billNumber]);

    useEffect(() => {
        const fetchData = async () => {
            try {
                let { data: PartyDetails, error } = await supabase
                    .from('PartyDetails')
                    .select('*');

                if (error) {
                    throw error;
                }

                console.log(PartyDetails);

                PartyDetails.forEach((party) => {
                    setPartyData((data) => { return { ...data, [party.party_code]: { name: party.party_name, address: party.party_address, gstin: party.party_gstin } } })
                });

            } catch (error) {
                console.error('Error fetching PartyDetails:', error.message);
            }
        };

        fetchData();
        form.getFieldValue({
            billNumber: getNextBillNumber()
        })

    }, []);

    if (billNum === null) {
        return <div>Loading...</div>;
    }

    const onFinish = (values) => {
        console.log('Success:', values);
        dispatch({ type: 'SET_BILL_NUMBER', payload: values.billNumber });
        dispatch({ type: 'SET_BRANCH', payload: values.branch });
        dispatch({ type: 'SET_LORRY_NUMBER', payload: values.lorryNumber });
        dispatch({ type: 'SET_PARTY_NAME', payload: values.partyName });
        dispatch({ type: 'SET_PARTY_ADDRESS', payload: values.partyAddress });
        dispatch({ type: 'SET_PARTY_GSTIN', payload: values.partyGstin });
        dispatch({ type: 'SET_DATE', payload: values.date });
        dispatch({ type: 'SET_PARTY_CODE', payload: values.partyCode });

        // Set Party Data
        if ((!values.partyCode || values.partyCode === 'clear') && values.partyName && values.partyAddress && values.partyGstin) {
            setPartyModalVisible(true);
        } else {
            next();
        }
    };

    const onFinishFailed = (errorInfo) => {
        console.log('Failed:', errorInfo);
    };

    const handlePartyCodeChange = (value) => {
        if (value === "clear") {
            form.setFieldsValue({
                partyName: "",
                partyAddress: "",
                partyGstin: ""
            });
        } else {
            setSelectedPartyCode(value);
            const selectedParty = partyData[value];
            if (selectedParty) {
                form.setFieldsValue({
                    partyName: selectedParty.name,
                    partyAddress: selectedParty.address,
                    partyGstin: selectedParty.gstin,
                });
            }
        }
    };

    const handlePartyModalOk = async (value) => {
        if (value) {
            const { data, error } = await supabase
                .from('PartyDetails')
                .insert([
                    { party_code: value, party_name: form.getFieldValue('partyName'), party_address: form.getFieldValue('partyAddress'), party_gstin: form.getFieldValue('partyGstin') },
                ])
                .select()

            setPartyModalVisible(false);
            next();
        }
    }

    return (
        <>
            <Form
                form={form}
                layout="vertical"
                className='form'
                initialValues={{
                    billNumber: billNumber || billNum,
                    branch: branch,
                    lorryNumber: lorryNumber,
                    partyName: partyName,
                    partyAddress: partyAddress,
                    partyGstin: partyGstin,
                    date: date,
                    partyCode: partyCode
                }}
                onFinish={onFinish}
                onFinishFailed={onFinishFailed}
            >
                <div className='form-two-input-div'>
                    <Form.Item
                        label="Bill Number"
                        name="billNumber"
                        className='form-two-input-items'
                    >
                        <Input placeholder='Enter Bill Number' disabled={true} value={'232'} />
                    </Form.Item>
                    <Form.Item
                        label="Date"
                        name="date"
                        className='form-two-input-items'
                        rules={[{ required: true, message: 'Please select the date' }]}
                    >
                        <DatePicker style={{ width: '100%' }} />
                    </Form.Item>
                </div>
                <div className='form-two-input-div'>
                    <Form.Item
                        label="Branch"
                        name="branch"
                        className='form-two-input-items'
                        rules={[{ required: true, message: 'Please enter the branch' }]}
                    >
                        <Input placeholder='Enter Branch' />
                    </Form.Item>
                    <Form.Item
                        label="Lorry Number"
                        name="lorryNumber"
                        className='form-two-input-items'
                        rules={[{ required: true, message: 'Please enter the lorry number' }]}
                    >
                        <Input />
                    </Form.Item>
                </div>
                <div className='form-two-input-div'>
                    <Form.Item
                        label="Party Code"
                        name="partyCode"
                        className='form-two-input-items'
                    >
                        <Select
                            style={{ textAlign: 'left' }}
                            onChange={handlePartyCodeChange}
                            placeholder="Select Party Code"
                        >
                            {/* Option for clearing partyName and partyAddress */}
                            <Select.Option key="clear" value="clear">
                                Clear
                            </Select.Option>

                            {/* Options from partyData */}
                            {Object.keys(partyData).map(code => (
                                <Select.Option key={code} value={code}>
                                    {code}
                                </Select.Option>
                            ))}
                        </Select>
                    </Form.Item>
                    <Form.Item
                        label="Party Name"
                        name="partyName"
                        className='form-two-input-items'
                        rules={[{ required: true, message: 'Please enter the party name' }]}
                    >
                        <Input />
                    </Form.Item>
                </div>
                <Form.Item
                    label="Party GSTIN"
                    name="partyGstin"
                    rules={[{ required: true, message: 'Please enter the party GSTIN' }]}
                >
                    <Input />
                </Form.Item>
                <Form.Item
                    label="Party Address"
                    name="partyAddress"
                    rules={[{ required: true, message: 'Please enter the party address' }]}
                >
                    <Input.TextArea
                        autoSize={{
                            minRows: 2,
                            maxRows: 5,
                        }}
                    />
                </Form.Item>
                <div
                    style={{
                        marginBlock: 30,
                        display: 'flex',
                        justifyContent: 'flex-end',
                    }}
                >
                    {current < steps.length - 1 && (
                        <Button type="primary" htmlType="submit">
                            Next
                        </Button>
                    )}
                    {current === steps.length - 1 && (
                        <Button
                            type="primary"
                            htmlType="submit"
                            onClick={() => message.success('Processing complete!')}
                        >
                            Done
                        </Button>
                    )}
                    {current > 0 && (
                        <Button
                            style={{
                                margin: '0 8px',
                            }}
                            onClick={() => prev()}
                        >
                            Previous
                        </Button>
                    )}
                </div>
            </Form>
            <AddPartyModal
                isModalOpen={partyModalVisible}
                handleCancel={() => {
                    setPartyModalVisible(false);
                    next();
                }}
                handleOk={handlePartyModalOk}
                partyName={form.getFieldValue('partyName')}
                partyGstin={form.getFieldValue('partyGstin')}
                partyAddress={form.getFieldValue('partyAddress')}
            />
        </>
    );
}

export default InvoiceDetails;
