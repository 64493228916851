import React from 'react';
import Step from '../Components/steps';
import "./common.css";

const NewInvoicePage = () => {
    return <div className="new-invoice-main">
        <Step />
    </div>;
};

export default NewInvoicePage;