import React, { useState } from 'react';
import { message, Modal } from 'antd';
import Input from 'antd/es/input/Input';

const AddPartyModal = ({ isModalOpen, handleOk, handleCancel, partyName, partyAddress, partyGstin }) => {

    const [partyCode, setPartyCode] = useState();

    return (
        <Modal title="Do you want to save this party data?" open={isModalOpen} onOk={() => { partyCode != '' ? handleOk(partyCode) : message.warning("Please enter the Party Code you want to save.") }} onCancel={handleCancel}>
            <p>Party Name: {partyName}</p>
            <p>Party GSTIN: {partyGstin}</p>
            <p>Party Address: {partyAddress}</p>
            <Input placeholder="Party Code" onChange={(e) => setPartyCode(e.target.value)} />
        </Modal>
    );
};

export default AddPartyModal;