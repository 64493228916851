import React, { useEffect } from 'react';
import { Card } from 'antd';
import { useNavigate } from 'react-router-dom';
import { FileAddOutlined, BarChartOutlined, HistoryOutlined } from '@ant-design/icons';
import './common.css'
import { useDispatch } from 'react-redux';
import NavBar from '../Components/navBar';

const MainPage = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const handleCardClick = (path) => {
        navigate(path);
    };

    useEffect(() => {
        dispatch({ type: 'CLEAR_INVOICE' });

    }, []);

    return (
        <>
            <NavBar />
            <div className='main-container'>
                <Card
                    title="New Invoice"
                    hoverable
                    onClick={() => handleCardClick('/new-invoice')}
                    className='main-card'
                    cover={<FileAddOutlined className='main-icon' />}
                >
                    <p className='card-description'>Create a new invoice for your clients.</p>
                </Card>
                <Card
                    title="Analytics"
                    hoverable
                    onClick={() => handleCardClick('/analytics')}
                    className='main-card'
                    cover={<BarChartOutlined className='main-icon' />}
                >
                    <p className='card-description'>View analytics and reports on your invoices.</p>
                </Card>
                <Card
                    title="Previous Invoices"
                    hoverable
                    onClick={() => handleCardClick('/prev-invoices')}
                    className='main-card'
                    cover={<HistoryOutlined className='main-icon' />}
                >
                    <p className='card-description'>View and manage previous invoices.</p>
                </Card>
            </div>
        </>
    );
};

export default MainPage;