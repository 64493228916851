import React, { useEffect, useState } from 'react';
import { Descriptions, Card, Table, Tag } from 'antd';
import { useParams } from 'react-router-dom';
import dayjs from 'dayjs';
import './common.css';

const columns = [
    {
        title: 'G.C. Number',
        dataIndex: 'gcNoteNumber',
        key: 'gcNoteNumber',
        render: (text, record) => record.gcNoteNumber ? record.gcNoteNumber : 'N/A'
    },
    {
        title: 'Date',
        dataIndex: 'gcNoteDate',
        key: 'gcNoteDate',
        render: (text, record) => dayjs(record.gcNoteDate).format('DD-MM-YYYY'),
    },
    {
        title: 'Destination',
        dataIndex: 'destination',
        key: 'destination',
        render: (text, record) => record.destination ? record.destination : 'N/A'
    },
    {
        title: 'Number of Pkgs.',
        dataIndex: 'numberOfPackages',
        key: 'numberOfPackages',
        render: (text, record) => record.numberOfPackages ? record.numberOfPackages : 'N/A'
    },
    {
        title: 'Particulars',
        dataIndex: 'particulars',
        key: 'particulars',
        render: (text, record) => record.particulars ? record.particulars : 'N/A'
    },
    {
        title: 'Weight',
        dataIndex: 'weight',
        key: 'weight',
        render: (text, record) => record.weight ? `${record.weight.toFixed(1)} Kg` : 'N/A'
    },
    {
        title: 'Rate per kg',
        dataIndex: 'ratePerKg',
        key: 'ratePerKg',
        render: (text, record) => record.ratePerKg ? `₹${record.ratePerKg.toFixed(2)}` : 'N/A'
    },
    {
        title: 'Freight',
        dataIndex: 'freight',
        key: 'freight',
        render: (text, record) => record.freight ? `₹${record.freight.toFixed(2)}` : 'N/A'
    },
];

function ViewInvoicePage() {
    const { id } = useParams();

    const [invoiceData, setInvoiceData] = useState(null);
    const [isLessThen600px, setIsLessThen600px] = useState(false);
    const [loadings, setLoadings] = useState([]);

    useEffect(() => {
        const data = localStorage.getItem('invoiceData');
        if (data) {
            setInvoiceData(JSON.parse(data));
        }
    }, [id]);
    useEffect(() => { window.addEventListener('resize', isScreenWidthOver600px); }, [window])

    if (!invoiceData) {
        return <div>No data found</div>;
    }

    const {
        bill_number,
        bill_date,
        branch,
        lorry_number,
        party_code,
        party_name,
        party_address,
        materials, 
        lr_charges,
        total_lr_charges,
        total_freight,
        sub_total,
        total,
        total_in_words,
        code
    } = invoiceData;

    const invoiceDetail = [
        { label: 'Bill Number', children: bill_number },
        { label: 'Bill Date', children: dayjs(bill_date).format('DD-MM-YYYY') },
        { label: 'Branch', children: branch },
        { label: 'Lorry Number', children: lorry_number },
        { label: 'Party Name', children: party_name },
        { label: 'Party Address', children: party_address }
    ];


    const billDetails = [
        { label: 'L.R. Charges', span: 3 },
        { label: 'Total L.R. Charges', children: total_lr_charges },
        { label: 'Total Freight', children: total_freight },
        { label: 'Sub Total', children: sub_total },
        { label: 'Total', children: total },
        { label: 'Total in Words', children: total_in_words, span: 2 }
    ];



    function isScreenWidthOver600px() {
        if (window.innerWidth > 600) {
            setIsLessThen600px(false);
        } else {
            setIsLessThen600px(true)
        }
    }


    return (
        <div style={{ padding: "40px", boxSizing: 'border-box' }}>
            <Card
                style={{ width: '100%' }}
            >
                <Descriptions title="Invoice Detail" bordered style={{ textAlign: 'left' }} extra={ <Tag color={code === 'BE' ? 'blue' : 'red'}>{code}</Tag>}>
                    {invoiceDetail.map((detail, index) => (
                        <Descriptions.Item key={index} label={detail.label} labelStyle={{ fontWeight: 'bold' }}>
                            {detail.children}
                        </Descriptions.Item>
                    ))}
                </Descriptions>

                <Descriptions title="Material Detail" style={{ textAlign: 'left', marginTop: '50px' }}></Descriptions>
                <div style={{ width: '100%', overflow: 'auto' }}>
                    <Table columns={columns} dataSource={JSON.parse(materials)} rowKey="gcNoteNumber" pagination={false} bordered />
                </div>

                <Descriptions title="Bill Details" style={{ textAlign: 'left', marginTop: '50px' }} bordered>
                    {!isLessThen600px && <Descriptions.Item label={billDetails[0].label} span={3} labelStyle={{ fontWeight: 'bold' }}>
                        <Table columns={[
                            { title: 'Charges', dataIndex: 'charges', key: 'charges', render: (text, record) => record.Charges ? record.Charges : 'N/A' },
                            { title: 'Remarks', dataIndex: 'remarks', key: 'remarks', render: (text, record) => record.Remarks ? record.Remarks : '' },
                        ]} dataSource={JSON.parse(lr_charges)} rowKey="lrCharges" pagination={false} bordered />
                    </Descriptions.Item>}
                    {billDetails.slice(1).map((detail, index) => (
                        <Descriptions.Item key={index} label={detail.label} span={detail.span || 1} labelStyle={{ fontWeight: 'bold' }}>
                            {detail.children}
                        </Descriptions.Item>
                    ))}
                </Descriptions>
            </Card>
        </div>
    );
}

export default ViewInvoicePage;
