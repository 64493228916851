import React, { useState, useEffect, useContext } from 'react';
import { Table, Input, Button, Tooltip, Tag } from 'antd';
import { DownloadOutlined, EditOutlined, EyeOutlined } from '@ant-design/icons';
import { useNavigate } from 'react-router-dom';
import supabase from '../db';
import './common.css';
import { useDispatch } from 'react-redux';
import dayjs from 'dayjs';
import generatePDF from '../Functions/generateBill';
import { ToggleContext } from '../Context/toggleContext';

const { Search } = Input;

const PrevInvoicesPage = () => {
    const [data, setData] = useState([]);
    const [filteredData, setFilteredData] = useState([]);
    const [searchText, setSearchText] = useState('');
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const { getCompanyData } = useContext(ToggleContext);

    useEffect(() => {
        const fetchData = async () => {
            const { data: BillDetails, error } = await supabase
                .from('BillDetails')
                .select('*');

            if (error) {
                console.error('Error fetching data:', error);
                return;
            }

            if (BillDetails) {
                console.log(BillDetails);
                setData(BillDetails);
                setFilteredData(BillDetails);
            }
        };

        fetchData();
    }, []);

    const handleSearch = (value) => {
        setSearchText(value);
        const filtered = data.filter((item) =>
            (item.bill_number && item.bill_number.includes(value)) ||
            (item.party_name && item.party_name.includes(value)) ||
            (item.bill_date && item.bill_date.includes(value))
        );
        setFilteredData(filtered);
    };

    const handleView = (record) => {
        // Ensure materials is an array
        localStorage.setItem('invoiceData', JSON.stringify(record));
        navigate(`/view-invoice/${record.id}`);
    };

    const convertDateToJson = (dateString) => {
        const dateObject = dayjs(dateString, "YYYY-MM-DD");
        const jsonDate = dateObject;

        return jsonDate;
    };

    const handleEdit = (record) => {
        console.log(record);
        dispatch({ type: "EDITING" });
        dispatch({ type: "SET_BILL_NUMBER", payload: record.bill_number });
        dispatch({ type: "SET_BRANCH", payload: record.branch });
        dispatch({ type: "SET_LORRY_NUMBER", payload: record.lorry_number });
        dispatch({ type: "SET_PARTY_NAME", payload: record.party_name });
        dispatch({ type: "SET_PARTY_ADDRESS", payload: record.party_address });
        dispatch({ type: "SET_PARTY_CODE", payload: record.party_code });
        dispatch({ type: "SET_MATERIALS", payload: JSON.parse(record.materials) });
        dispatch({ type: "SET_LR_CHARGES_ITEMS", payload: JSON.parse(record.lr_charges) });
        dispatch({ type: "SET_TOTAL_FREIGHT", payload: record.total_freight });
        dispatch({ type: "SET_SUB_TOTAL", payload: record.sub_total });
        dispatch({ type: "SET_SUB_TOTAL_FREIGHT", payload: record.total_freight });
        dispatch({ type: "SET_SUB_TOTAL_LR_CHARGES", payload: record.total_lr_charges });
        dispatch({ type: "SET_TOTAL", payload: record.total });
        dispatch({ type: "SET_TOTAL_IN_WORDS", payload: record.total_in_words });
        dispatch({ type: "SET_DATE", payload: convertDateToJson(record.bill_date) });
        navigate(`/edit-invoice`);
    }

    const columns = [
        {
            title: 'Code',
            dataIndex: 'code',
            key: 'code',
            filters: [
                { text: 'BE', value: 'BE' },
                { text: 'BCX', value: 'BCX' },
            ],
            onFilter: (value, record) => record.code.includes(value),
            render: (text) => (
                <Tag color={text === 'BE' ? 'blue' : 'red'}>
                    {text}
                </Tag>
            ),
        },
        {
            title: 'Bill Number',
            dataIndex: 'bill_number',
            key: 'bill_number',
        },
        {
            title: 'Date',
            dataIndex: 'bill_date',
            key: 'bill_date',
            sorter: (a, b) => new Date(a.bill_date) - new Date(b.bill_date),
        },
        {
            title: 'Party Name',
            dataIndex: 'party_name',
            key: 'party_name',
        },
        {
            title: 'Total',
            dataIndex: 'total',
            key: 'total',
            sorter: (a, b) => a.total - b.total,
            render: (text) => `₹${text}`
        },
        {
            title: 'Actions',
            key: 'actions',
            render: (text, record) => (
                <div className="actions-column">
                    <Tooltip title="View">
                        <Button icon={<EyeOutlined />} onClick={() => handleView(record)} />
                    </Tooltip>
                    <Tooltip title="Edit">
                        <Button icon={<EditOutlined />}
                            // onClick={() => handleEdit(record)}
                            onClick={() => { alert("This feature is not yet available. Due to a bug, the edit button is disabled.") }}
                        />
                    </Tooltip>
                    <Tooltip title="Download">
                        <Button icon={<DownloadOutlined />} onClick={() => { generatePDF(record, getCompanyData(record.code)) }} />
                    </Tooltip>
                </div>
            ),
            className: 'actions-cell',
        },
    ];

    return (
        <div className="prev-invoices-container">
            <Search
                placeholder="Search by Date, Bill Number or Party Name"
                onSearch={handleSearch}
                onChange={(e) => handleSearch(e.target.value)}
                value={searchText}
                style={{ marginBottom: 20 }}
            />
            <div className='prev-table-div'>
                <Table columns={columns} dataSource={filteredData} rowKey="id" />
            </div>
        </div>
    );
};

export default PrevInvoicesPage;