import React, { useEffect } from 'react';
import { Button, message, InputNumber, Form, Input } from 'antd';
import { CloseOutlined } from '@ant-design/icons';
import numbToWord from '../../Functions/numToWord';
import { useSelector, useDispatch } from 'react-redux';
import "../common.css"

function BillDetails({ current, steps, prev, next }) {
    const dispatch = useDispatch();
    const [form] = Form.useForm();

    const totalFreight = useSelector(state => state.totalFreight);
    const lrChargesItems = useSelector(state => state.lrChargesItems);

    const subTotalFreight = useSelector(state => state.subTotalFreight);
    const totalInWords = useSelector(state => state.totalInWords);
    const total = useSelector(state => state.total);
    const subTotalLRCharges = useSelector(state => state.subTotalLRCharges);
    const subTotal = useSelector(state => state.subTotal);

    const onFinish = (values) => {
        console.log('Success:', values);

        dispatch({ type: 'SET_LR_CHARGES_ITEMS', payload: values.lrChargesItems });
        dispatch({ type: 'SET_SUB_TOTAL_FREIGHT', payload: values.subTotalFreight });
        dispatch({ type: 'SET_TOTAL_FREIGHT', payload: values.totalFreight });
        dispatch({ type: 'SET_TOTAL_IN_WORDS', payload: values.totalInWords });
        dispatch({ type: 'SET_TOTAL', payload: values.total });
        dispatch({ type: 'SET_SUB_TOTAL_LR_CHARGES', payload: values.subTotalLRCharges });
        dispatch({ type: 'SET_SUB_TOTAL', payload: values.subTotal });
        next();
    };

    const onFinishFailed = (errorInfo) => {
        console.log('Failed:', errorInfo);
    };

    const onValuesChange = (changedValues, allValues) => {
        let totalLR = 0;
        if (changedValues.lrChargesItems || changedValues.subTotalFreight) {
            allValues.lrChargesItems.forEach((item) => {
                totalLR += item?.Charges || 0;
            });
            form.setFieldsValue({
                subTotalLRCharges: totalLR,
                subTotal: totalLR + allValues.subTotalFreight,
                total: totalLR + allValues.subTotalFreight,
                totalInWords: `${numbToWord(totalLR + allValues.subTotalFreight) || 0} Only`
            });
        }
    };

    return (
        <Form
            layout="vertical"
            onFinish={onFinish}
            className="form"
            onFinishFailed={onFinishFailed}
            onValuesChange={onValuesChange}
            form={form}
            initialValues={{
                lrChargesItems: lrChargesItems,
                subTotalFreight: subTotalFreight || totalFreight,
                totalInWords: totalInWords,
                total: total,
                subTotalLRCharges: subTotalLRCharges,
                subTotal: subTotal
            }}
        >
            <Form.Item label="L.R. Charges">
                <Form.List name="lrChargesItems">
                    {(fields, { add, remove }) => (
                        <div style={{ display: 'flex', flexDirection: 'column' }}>
                            {fields.map((field) => (
                                <div key={field.key} className='form-two-input-div'>
                                    <Form.Item
                                        name={[field.name, 'Charges']}
                                        className='form-two-input-items'
                                        rules={[{ required: true, message: 'Please input Charges!' }]}
                                    >
                                        <InputNumber placeholder="Charges" min={1} prefix="₹" style={{ width: '100%' }} />
                                    </Form.Item>
                                    <Form.Item
                                        name={[field.name, 'Remarks']}
                                        className='form-two-input-items'
                                    >
                                        <Input placeholder="Remarks" />
                                    </Form.Item>
                                    {fields.length > 1 && (
                                        <CloseOutlined onClick={() => remove(field.name)} style={{ height: 32 }} />
                                    )}
                                </div>
                            ))}

                            <Button type="dashed" onClick={() => add()} block>
                                + L.R. Charges
                            </Button>
                        </div>
                    )}
                </Form.List>
            </Form.Item>
            <div className='form-two-input-div'>
                <Form.Item
                    label="Total L.R. Charges"
                    name="subTotalLRCharges"
                    className='form-two-input-items'
                    rules={[{ required: true, message: 'Please input Total L.R. Charges!' }]}
                >
                    <InputNumber style={{ width: '100%' }} min={1} prefix="₹" readOnly />
                </Form.Item>
                <Form.Item
                    label="Total Freight"
                    name="subTotalFreight"
                    className='form-two-input-items'
                    rules={[{ required: true, message: 'Please input Total Freight!' }]}
                >
                    <InputNumber style={{ width: '100%' }} min={1} prefix="₹" />
                </Form.Item>
            </div>
            <div className='form-two-input-div'>
                <Form.Item
                    label="Sub Total"
                    name="subTotal"
                    className='form-two-input-items'
                    rules={[{ required: true, message: 'Please input Sub Total!' }]}
                >
                    <InputNumber style={{ width: '100%' }} min={1} prefix="₹" />
                </Form.Item>
                <Form.Item
                    label="Total"
                    name="total"
                    className='form-two-input-items'
                    rules={[{ required: true, message: 'Please input Total!' }]}
                >
                    <InputNumber style={{ width: '100%' }} min={1} prefix="₹" />
                </Form.Item>
            </div>
            <Form.Item
                label="Total in Words"
                name="totalInWords"
                rules={[{ required: true, message: 'Please input Total in Words!' }]}
            >
                <Input.TextArea
                    autoSize={{
                        minRows: 1,
                        maxRows: 2,
                    }} />
            </Form.Item>
            <div style={{ marginBlock: 30, display: 'flex', justifyContent: 'flex-end' }}>
                {current < steps.length - 1 && (
                    <Button type="primary" htmlType="submit">
                        Next
                    </Button>
                )}
                {current === steps.length - 1 && (
                    <Button type="primary" htmlType="submit">
                        Done
                    </Button>
                )}
                {current > 0 && (
                    <Button style={{ margin: '0 8px' }} onClick={() => prev()}>
                        Previous
                    </Button>
                )}
            </div>
        </Form>
    );
}

export default BillDetails;
