import { useContext, useCallback } from "react";
import supabase from "../db";
import { useSelector, useDispatch } from 'react-redux';
import { ToggleContext } from "../Context/toggleContext";

const useNextBillNumber = () => {
    const dispatch = useDispatch();
    const { toggle, toggleValue } = useContext(ToggleContext);

    const getNextBillNumber = useCallback(async () => {
        const { data, error } = await supabase
            .from('BillDetails')
            .select('bill_number')
            .eq('code', toggle);

        if (error) {
            console.error('Error fetching data:', error);
            return;
        }

        const maxBillNumber = data.length > 0 ? Math.max(...data.map(item => parseInt(item.bill_number, 10))) : toggle === 'BE' ? 100057 : 50025;
        const newBillNumber = maxBillNumber + 1;

        return newBillNumber;
    }, [dispatch, toggle]);

    return getNextBillNumber;
};

export default useNextBillNumber;