import React, { useState, useEffect } from 'react';
import { Steps } from 'antd';
import MaterialDetails from './StepsComponents/materialDetails';
import Confirmation from './StepsComponents/confirmation';
import InvoiceDetails from './StepsComponents/InvoiceDetails';
import BillDetails from './StepsComponents/billDetails';
import './common.css';

function Step() {

    // State
    const [current, setCurrent] = useState(0);

    // Constants
    const steps = [
        {
            title: 'Invoice Details',
            content: 'First-content',
        },
        {
            title: 'Material Details',
            content: 'Second-content',
        },
        {
            title: 'Bill Details',
            content: 'Third-content',
        },
        {
            title: 'Confirmation',
            content: 'Last-content',
        },
    ];

    const items = steps.map((item) => ({
        key: item.title,
        title: item.title,
    }));

    // Functions
    const next = () => {
        setCurrent(current + 1);
    };

    const prev = () => {
        setCurrent(current - 1);
    };

    const [isLessThen600px, setIsLessThen600px] = useState(false);

    function isScreenWidthOver600px() {
        if (window.innerWidth > 600) {
            setIsLessThen600px(false);
        } else {
            setIsLessThen600px(true)
        }
    }

    useEffect(() => { window.addEventListener('resize', isScreenWidthOver600px); }, [window])

    return (
        <>
            <div style={{ width: '100%', overflow: 'auto' }}>
                <Steps current={current} items={items} type={isLessThen600px ? 'inline' : 'default'} />
            </div>
            <div className="steps-content-div">{
                steps[current].content === 'First-content' ? (
                    <InvoiceDetails
                        current={current}
                        steps={steps}
                        next={next}
                        prev={prev}
                    />
                ) :
                    steps[current].content === 'Second-content' ? (
                        <MaterialDetails
                            current={current}
                            steps={steps}
                            next={next}
                            prev={prev}
                        />
                    ) : steps[current].content === 'Third-content' ? (
                        < BillDetails
                            current={current}
                            steps={steps}
                            next={next}
                            prev={prev}
                        />
                    ) : <Confirmation
                        current={current}
                        steps={steps}
                        next={next}
                        prev={prev}
                    />
            }</div>
        </>
    );
}

export default Step;