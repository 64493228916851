import capitalizeWords from './capitalizeWords';
import { toWords } from 'number-to-words';

const ones = [
    '', 'one', 'two', 'three', 'four', 'five', 'six', 'seven', 'eight', 'nine',
    'ten', 'eleven', 'twelve', 'thirteen', 'fourteen', 'fifteen', 'sixteen', 'seventeen', 'eighteen', 'nineteen'
];
const tens = ['', '', 'twenty', 'thirty', 'forty', 'fifty', 'sixty', 'seventy', 'eighty', 'ninety'];

function numbToWord(number) {
    const crores = Math.floor(number / 10000000);
    const lakhs = Math.floor((number % 10000000) / 100000);
    const thousands = Math.floor((number % 100000) / 1000);
    const hundreds = Math.floor((number % 1000) / 100);
    const remainder = number % 100;

    let words = '';

    if (crores > 0) {
        words += `${toWords(crores)} crore `;
    }

    if (lakhs > 0) {
        words += `${toWords(lakhs)} lakh `;
    }

    if (thousands > 0) {
        words += `${toWords(thousands)} thousand `;
    }

    if (hundreds > 0) {
        words += `${toWords(hundreds)} hundred `;
    }

    if (remainder > 0) {
        if (remainder < 20) {
            words += `${ones[remainder]} `;
        } else {
            words += `${tens[Math.floor(remainder / 10)]} ${ones[remainder % 10]} `;
        }
    }

    return capitalizeWords(words.trim());
}

export default numbToWord;