import React, { useEffect, useState, useContext } from 'react';
import { Descriptions, Card, Button, Table } from 'antd';
import { useSelector } from 'react-redux';
import dayjs from 'dayjs';
import { DownloadOutlined } from '@ant-design/icons';
import supabase from "../../db";
import { ToggleContext } from '../../Context/toggleContext';
import generatePDF from '../../Functions/generateBill';
import ConfirmModal from '../confirmModal';

const columns = [
    {
        title: 'G.C. Number',
        dataIndex: 'gcNoteNumber',
        key: 'gcNoteNumber',
        render: (text, record) => record.gcNoteNumber ? record.gcNoteNumber : 'N/A'
    },
    {
        title: 'Date',
        dataIndex: 'gcNoteDate',
        key: 'gcNoteDate',
        render: (text, record) => dayjs(record.gcNoteDate).format('DD-MM-YYYY'),
    },
    {
        title: 'Destination',
        dataIndex: 'destination',
        key: 'destination',
        render: (text, record) => record.destination ? record.destination : 'N/A'
    },
    {
        title: 'Number of Pkgs.',
        dataIndex: 'numberOfPackages',
        key: 'numberOfPackages',
        render: (text, record) => record.numberOfPackages ? record.numberOfPackages : 'N/A'
    },
    {
        title: 'Particulars',
        dataIndex: 'particulars',
        key: 'particulars',
        render: (text, record) => record.particulars ? record.particulars : 'N/A'
    },
    {
        title: 'Weight',
        dataIndex: 'weight',
        key: 'weight',
        render: (text, record) => record.weight ? record.weight === 'FTL' ? "FTL" : `${record.weight.toFixed(1)} Kg` : 'N/A'
    },
    {
        title: 'Rate per kg',
        dataIndex: 'ratePerKg',
        key: 'ratePerKg',
        render: (text, record) => record.ratePerKg ? record.ratePerKg === 'Fix' ? 'Fix' : `₹${record.ratePerKg.toFixed(2)}` : 'N/A'
    },
    {
        title: 'Freight',
        dataIndex: 'freight',
        key: 'freight',
        render: (text, record) => record.freight ? `₹${record.freight.toFixed(2)}` : 'N/A'
    },
];

function Confirmation({ current, steps, next, prev }) {

    const { toggle, toggleValue, data: companyData } = useContext(ToggleContext);

    const materials = useSelector(state => state.materials);
    const lrChargesItems = useSelector(state => state.lrChargesItems);
    const billNumber = useSelector(state => state.billNumber);
    const branch = useSelector(state => state.branch);
    const date = useSelector(state => state.date);
    const lorryNumber = useSelector(state => state.lorryNumber);
    const partyName = useSelector(state => state.partyName);
    const partyCode = useSelector(state => state.partyCode);
    const partyAddress = useSelector(state => state.partyAddress);
    const subTotalFreight = useSelector(state => state.subTotalFreight);
    const totalInWords = useSelector(state => state.totalInWords);
    const total = useSelector(state => state.total);
    const subTotalLRCharges = useSelector(state => state.subTotalLRCharges);
    const subTotal = useSelector(state => state.subTotal);
    const partyGstin = useSelector(state => state.partyGstin);
    const isEditing = useSelector(state => state.isEditing);

    const [confirmModalVisible, setConfirmModalVisible] = useState(false);

    // Convert gcNoteDate from Dayjs object to JavaScript Date object
    const updatedMaterials = materials.map((material) => {
        if (dayjs.isDayjs(material.gcNoteDate)) {
            return {
                ...material,
                gcNoteDate: material.gcNoteDate.toDate()
            };
        }
        return material;
    });

    const invoiceDetail = [
        { label: 'Bill Number', children: billNumber },
        { label: 'Bill Date', children: dayjs(date).format('DD-MM-YYYY') },
        { label: 'Branch', children: branch },
        { label: 'Lorry Number', children: lorryNumber },
        { label: 'Party Name', children: partyName },
        { label: 'Party Address', children: partyAddress }
    ];

    const [loadings, setLoadings] = useState([]);
    const enterLoading = (index) => {
        setLoadings((prevLoadings) => {
            const newLoadings = [...prevLoadings];
            newLoadings[index] = true;
            return newLoadings;
        });
        setTimeout(() => {
            setLoadings((prevLoadings) => {
                const newLoadings = [...prevLoadings];
                newLoadings[index] = false;
                return newLoadings;
            });
        }, 6000);
    };

    const billDetails = [
        { label: 'L.R. Charges', span: 3 },
        { label: 'Total L.R. Charges', children: `₹${subTotalLRCharges}` },
        { label: 'Total Freight', children: `₹${subTotalFreight}` },
        { label: 'Sub Total', children: `₹${subTotal}` },
        { label: 'Total', children: `₹${total}` },
        { label: 'Total in Words', children: totalInWords, span: 2 }
    ];

    const [isLessThen600px, setIsLessThen600px] = useState(false);

    function isScreenWidthOver600px() {
        if (window.innerWidth > 600) {
            setIsLessThen600px(false);
        } else {
            setIsLessThen600px(true)
        }
    }

    useEffect(() => { window.addEventListener('resize', isScreenWidthOver600px); }, [window])


    const saveAndGenerateBill = async () => {
        enterLoading(1);

        const { data, error } = await supabase
            .from('BillDetails')
            .insert([
                {
                    bill_number: billNumber,
                    bill_date: date,
                    branch: branch,
                    lorry_number: lorryNumber,
                    party_code: partyCode,
                    party_name: partyName,
                    party_address: partyAddress,
                    materials: JSON.stringify(materials),
                    lr_charges: JSON.stringify(lrChargesItems),
                    total_lr_charges: subTotalLRCharges,
                    total_freight: subTotalFreight,
                    sub_total: subTotal,
                    total: total,
                    total_in_words: totalInWords,
                    code: toggle,
                    party_gstin: partyGstin
                },
            ])
            .select()

        console.log(data, error);
        console.log("isEditing", isEditing);

        if (data && !error) {
            generatePDF(data[0], companyData);
        } else {
            console.error("Error inserting data:", error);
            alert("Failed to save bill details. Please try again.");
        }
    }

    const handleDownload = async () => {
        setConfirmModalVisible(true);
        // saveAndGenerateBill();
    }

    return (
        <>
            <Card
                title="Detail Confirmation"
                extra={<Button onClick={prev}>Edit</Button>}
                style={{ width: '100%' }}
            >
                <Descriptions title="Invoice Detail" bordered style={{ textAlign: 'left' }}>
                    {invoiceDetail.map((detail, index) => (
                        <Descriptions.Item key={index} label={detail.label} labelStyle={{ fontWeight: 'bold' }}>
                            {detail.children}
                        </Descriptions.Item>
                    ))}
                </Descriptions>

                <Descriptions title="Material Detail" style={{ textAlign: 'left', marginTop: '50px' }}></Descriptions>
                <div style={{ width: '100%', overflow: 'auto' }}>
                    <Table columns={columns} dataSource={updatedMaterials} rowKey="gcNoteNumber" pagination={false} bordered />
                </div>

                <Descriptions title="Bill Details" style={{ textAlign: 'left', marginTop: '50px' }} bordered>
                    {!isLessThen600px && <Descriptions.Item label={billDetails[0].label} span={3} labelStyle={{ fontWeight: 'bold' }}>
                        <Table columns={[
                            { title: 'Charges', dataIndex: 'charges', key: 'charges', render: (text, record) => record.Charges ? `₹${record.Charges}` : 'N/A' },
                            { title: 'Remarks', dataIndex: 'remarks', key: 'remarks', render: (text, record) => record.Remarks ? record.Remarks : '' },
                        ]} dataSource={lrChargesItems} rowKey="lrCharges" pagination={false} bordered />
                    </Descriptions.Item>}
                    {billDetails.slice(1).map((detail, index) => (
                        <Descriptions.Item key={index} label={detail.label} span={detail.span || 1} labelStyle={{ fontWeight: 'bold' }}>
                            {detail.children}
                        </Descriptions.Item>
                    ))}
                </Descriptions>

                <div style={{ display: 'flex', justifyContent: 'end', marginTop: '50px' }}>
                    <Button
                        type="primary"
                        icon={<DownloadOutlined />}
                        size={'large'}
                        loading={loadings[1]}
                        onClick={() => handleDownload()}
                    >
                        Download
                    </Button>
                </div>
            </Card>
            <ConfirmModal
                isModalOpen={confirmModalVisible}
                handleCancel={() => setConfirmModalVisible(false)}
                handleOk={() => {
                    saveAndGenerateBill();
                    setConfirmModalVisible(false);
                }}
            />
        </>
    );
}

export default Confirmation;
