import React, { useContext } from 'react';
import { Button } from 'antd';
import { ToggleContext } from '../Context/toggleContext';
import './common.css'

const NavBar = () => {
    const { toggle, toggleValue } = useContext(ToggleContext);

    return (
        <div className='navbar' style={{ backgroundColor: toggle === 'BE' ? 'aliceblue' : 'lightpink' }}>
            <h1>{toggle}</h1>
            <Button onClick={toggleValue} className='toggleButton'>
                Toggle
            </Button>
        </div>
    );
};

export default NavBar;