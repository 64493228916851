import React, { useEffect } from 'react';
import { Button, DatePicker, message, InputNumber, Card, Form, Input } from 'antd';
import { CloseOutlined } from '@ant-design/icons';
import { useSelector, useDispatch } from 'react-redux';
import dayjs from 'dayjs';
import "../common.css";

function MaterialDetails({ current, steps, next, prev }) {
    const dispatch = useDispatch();
    const [form] = Form.useForm();

    const materials = useSelector(state => state.materials);
    const isEditing = useSelector(state => state.isEditing);

    const convertDateToJson = (dateString) => {
        let dateObject;
        if (dayjs(dateString, "DD-MM-YYYY", true).isValid()) {
            dateObject = dayjs(dateString, "DD-MM-YYYY");
        } else if (dayjs(dateString, "YYYY-MM-DD", true).isValid()) {
            dateObject = dayjs(dateString, "YYYY-MM-DD");
        } else if (dayjs(dateString).isValid()) {
            dateObject = dayjs(dateString);
        } else {
            throw new Error("Invalid date format");
        }

        return dateObject;
    };

    const onFinish = (values) => {
        console.log('Success:', values);
        let total = 0;
        values.materials.forEach((material) => {
            total += material.freight;
        });
        console.log(total);
        dispatch({ type: 'SET_MATERIALS', payload: values.materials });
        dispatch({ type: 'SET_TOTAL_FREIGHT', payload: total });
        next();
    };

    const onFinishFailed = (errorInfo) => {
        console.log('Failed:', errorInfo);
    };

    const onValuesChange = (changedValues, allValues) => {
        const updatedMaterials = allValues.materials.map((material, index) => {
            const updatedMaterial = { ...material };

            if (!material.weight || material.weight < 1) {
                updatedMaterial.weight = "FTL";
            }

            if (!material.ratePerKg || material.ratePerKg < 1) {
                updatedMaterial.ratePerKg = "Fix";
            }

            if (changedValues.materials[index]?.ratePerKg || changedValues.materials[index]?.weight) {
                const freight = (updatedMaterial.weight !== "FTL" ? updatedMaterial.weight : 0) *
                    (updatedMaterial.ratePerKg !== "Fix" ? updatedMaterial.ratePerKg : 0);
                updatedMaterial.freight = freight;
            }

            return updatedMaterial;
        });

        form.setFieldsValue({ materials: updatedMaterials });
    };

    let formattedMaterials = [];

    useEffect(() => {
        if (isEditing) {
            formattedMaterials = materials.map((material) => ({
                ...material,
                gcNoteDate: convertDateToJson(material.gcNoteDate),
            }));
            form.setFieldsValue({ materials: formattedMaterials });
        }
    }, [materials]);

    return (
        <Form
            form={form}
            layout="vertical"
            className="material-form"
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
            initialValues={{ materials: isEditing ? formattedMaterials : materials }}
            onValuesChange={onValuesChange}
        >
            <Form.List name="materials">
                {(fields, { add, remove }) => (
                    <div
                        style={{
                            display: 'flex',
                            rowGap: 16,
                            flexDirection: 'column',
                        }}
                    >
                        {fields.map((field) => (
                            <Card
                                size="small"
                                title={`Material ${field.name + 1}`}
                                key={field.key}
                                extra={
                                    fields.length > 1 ? (
                                        <CloseOutlined
                                            onClick={() => remove(field.name)}
                                        />
                                    ) : null
                                }
                            >
                                <div className="form-two-input-div">
                                    <Form.Item
                                        label="G.C. Number"
                                        name={[field.name, 'gcNoteNumber']}
                                        className="form-two-input-items"
                                        rules={[{ required: true, message: 'Please input G.C. Number!' }]}
                                    >
                                        <Input />
                                    </Form.Item>
                                    <Form.Item
                                        label="Date"
                                        name={[field.name, 'gcNoteDate']}
                                        className="form-two-input-items"
                                        rules={[{ required: true, message: 'Please select Date!' }]}
                                    >
                                        <DatePicker style={{ width: '100%' }} />
                                    </Form.Item>
                                </div>
                                <div className="form-two-input-div">
                                    <Form.Item
                                        label="Destination"
                                        name={[field.name, 'destination']}
                                        className="form-two-input-items"
                                        rules={[{ required: true, message: 'Please input Destination!' }]}
                                    >
                                        <Input />
                                    </Form.Item>
                                    <Form.Item
                                        label="Number of Pkgs."
                                        name={[field.name, 'numberOfPackages']}
                                        className="form-two-input-items"
                                        rules={[{ required: true, message: 'Please input Number of Packages!' }]}
                                    >
                                        <InputNumber style={{ width: '100%' }} min={1} step={0.01} precision={2} />
                                    </Form.Item>
                                </div>
                                <div className="form-two-input-div">
                                    <Form.Item
                                        label="Particulars"
                                        name={[field.name, 'particulars']}
                                        className="form-two-input-items"
                                        rules={[{ required: true, message: 'Please input Particulars!' }]}
                                    >
                                        <Input />
                                    </Form.Item>
                                    <Form.Item
                                        label="Weight"
                                        name={[field.name, 'weight']}
                                        className="form-two-input-items"
                                    >
                                        <InputNumber style={{ width: '100%' }} min={0} suffix="Kg" step={0.0001} precision={4} />
                                    </Form.Item>
                                </div>
                                <div className="form-two-input-div">
                                    <Form.Item
                                        label="Rate per kg"
                                        name={[field.name, 'ratePerKg']}
                                        className="form-two-input-items"
                                    >
                                        <InputNumber style={{ width: '100%' }} min={0} prefix="₹" step={0.01} precision={2} />
                                    </Form.Item>
                                    <Form.Item
                                        label="Freight"
                                        name={[field.name, 'freight']}
                                        className="form-two-input-items"
                                        rules={[{ required: true, message: 'Please input Freight!' }]}
                                    >
                                        <InputNumber style={{ width: '100%' }} min={1} prefix="₹" step={0.01} precision={2} />
                                    </Form.Item>
                                </div>
                            </Card>
                        ))}

                        <Button type="dashed" onClick={() => add()} block>
                            + Material
                        </Button>
                    </div>
                )}
            </Form.List>
            <div
                style={{
                    marginBlock: 30,
                    display: 'flex',
                    justifyContent: 'flex-end',
                }}
            >
                {current < steps.length - 1 && (
                    <Button type="primary" htmlType="submit">
                        Next
                    </Button>
                )}
                {current === steps.length - 1 && (
                    <Button type="primary" htmlType="submit" onClick={() => message.success('Processing complete!')}>
                        Done
                    </Button>
                )}
                {current > 0 && (
                    <Button
                        style={{
                            margin: '0 8px',
                        }}
                        onClick={() => prev()}
                    >
                        Previous
                    </Button>
                )}
            </div>
        </Form>
    );
}

export default MaterialDetails;