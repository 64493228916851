const initialState = {
    billNumber: '',
    date: '',
    branch: '',
    lorryNumber: '',
    partyName: '',
    partyAddress: '',
    partyGstin: '',
    partyCode: '',
    materials: [{ freight: 0 }],
    totalFreight: '',
    lrChargesItems: [{}],
    subTotal: '',
    subTotalFreight: '',
    subTotalLRCharges: '',
    total: '',
    totalInWords: '',
    invoiceData: '',
    isEditing: false,
};

const rotateReducer = (state = initialState, action) => {
    switch (action.type) {
        case "EDITING":
            return {
                ...state,
                isEditing: true
            };
        case "EDITING_OVER":
            return {
                ...state,
                isEditing: false
            };
        case "SET_BILL_NUMBER":
            return {
                ...state,
                billNumber: action.payload
            };
        case "SET_DATE":
            return {
                ...state,
                date: action.payload
            };
        case "SET_BRANCH":
            return {
                ...state,
                branch: action.payload
            };
        case "SET_LORRY_NUMBER":
            return {
                ...state,
                lorryNumber: action.payload
            };
        case "SET_PARTY_NAME":
            return {
                ...state,
                partyName: action.payload
            };
        case "SET_PARTY_ADDRESS":
            return {
                ...state,
                partyAddress: action.payload
            };
        case "SET_PARTY_GSTIN":
            return {
                ...state,
                partyGstin: action.payload
            };
        case "SET_PARTY_CODE":
            return {
                ...state,
                partyCode: action.payload
            };
        case "SET_MATERIALS":
            return {
                ...state,
                materials: action.payload
            };
        case "SET_TOTAL_FREIGHT":
            return {
                ...state,
                totalFreight: action.payload
            };
        case "SET_LR_CHARGES_ITEMS":
            return {
                ...state,
                lrChargesItems: action.payload
            };
        case "SET_SUB_TOTAL":
            return {
                ...state,
                subTotal: action.payload
            };
        case "SET_SUB_TOTAL_FREIGHT":
            return {
                ...state,
                subTotalFreight: action.payload
            };
        case "SET_SUB_TOTAL_LR_CHARGES":
            return {
                ...state,
                subTotalLRCharges: action.payload
            };
        case "SET_TOTAL":
            return {
                ...state,
                total: action.payload
            };
        case "SET_TOTAL_IN_WORDS":
            return {
                ...state,
                totalInWords: action.payload
            };
        case "SET_INVOICE_DATA":
            return {
                ...state,
                invoiceData: action.payload
            };
        case "CLEAR_INVOICE_DATA":
            return {
                ...state,
                invoiceData: ''
            };
        case "CLEAR_INVOICE":
            return {
                ...state,
                billNumber: '',
                date: '',
                branch: '',
                lorryNumber: '',
                partyName: '',
                partyAddress: '',
                partyGstin: '',
                partyCode: '',
                materials: [{ freight: 0 }],
                totalFreight: '',
                lrChargesItems: [{}],
                subTotal: '',
                subTotalFreight: '',
                subTotalLRCharges: '',
                total: '',
                totalInWords: '',
                isEditing: false,
            }
        default:
            return state;
    }
};

export default rotateReducer;
