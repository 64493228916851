import React, { useState, useEffect } from 'react';
import { Card, Row, Col, Statistic, Tag } from 'antd';
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';
import './common.css';


const AnalyticsPage = () => {

    const dailyWorkData = [
        { day: '2024-06-01', BE: 5, BCX: 5 },
        { day: '2024-06-02', BE: 10, BCX: 5 },
        { day: '2024-06-03', BE: 0, BCX: 1 },
        { day: '2024-06-04', BE: 7, BCX: 6 },
        { day: '2024-06-05', BE: 0, BCX: 0 },
        { day: '2024-06-06', BE: 6, BCX: 6 },
        { day: '2024-06-07', BE: 3, BCX: 4 },
        { day: '2024-06-08', BE: 9, BCX: 10 },
    ];

    return (
        <div className="analytics-page">
            <div className='analytic-quick-div'>
                <Card><Statistic className="quick-statistic" title="Invoice Created" value={1} /></Card>
                <Card><Statistic className="quick-statistic" title="Total Work Cost" value={1} /></Card>
                <Card><Statistic className="quick-statistic" title="Work Cost by BE" value={1} suffix={<Tag color={'blue'}>BE</Tag>} /></Card>
                <Card><Statistic className="quick-statistic" title="Work Cost by BCX" value={1} suffix={<Tag color={'red'}>BCX</Tag>} /></Card>
            </div>
            <Card title="Daily Work Done This Month">
                <div className="graph-container">
                    <ResponsiveContainer width="100%" height={400}>
                        <BarChart data={dailyWorkData}>
                            <XAxis dataKey="day" />
                            <YAxis />
                            <Tooltip />
                            <Legend />
                            <Bar dataKey="BE" fill="#48cae4" stackId="a" />
                            <Bar dataKey="BCX" fill="#c9184a" stackId="a" />
                        </BarChart>
                    </ResponsiveContainer>
                </div>
            </Card>
        </div>
    );
};

export default AnalyticsPage;