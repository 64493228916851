import React, { createContext, useState } from 'react';

export const ToggleContext = createContext();

export const ToggleProvider = ({ children }) => {
    const [toggle, setToggle] = useState('BE');
    const [data, setData] = useState({
        partyCode: 'BE',
        partyGSTIN: '03CHDPS2678NIZT',
        partyAddress: '97 Transport Nagar, Ludhiana',
        partyPANNo: 'CHDPS2678N',
        partyEmail: 'balakexpress@gmail.com',
        partyName: 'Balak Express',
    });

    const toggleValue = () => {
        setToggle((prev) => (prev === 'BE' ? 'BCX' : 'BE'));
        setData((prev) => (
            prev.partyCode === 'BE' ?
                {
                    partyCode: 'BCX',
                    partyGSTIN: '03BEZPS8372B1Z0',
                    partyAddress: '#302. St.No. 8, S.C. Jamalpur, Ludhiana',
                    partyPANNo: 'BEZPS8372B',
                    partyEmail: 'balak.cargoxpress@gmail.com',
                    partyName: 'Balak Cargo Xpress'
                }
                :
                {
                    partyCode: 'BE',
                    partyGSTIN: '03CHDPS2678NIZT',
                    partyAddress: '97 Transport Nagar, Ludhiana',
                    partyPANNo: 'CHDPS2678N',
                    partyEmail: 'balakexpress@gmail.com',
                    partyName: 'Balak Express'
                }));
    };

    const getCompanyData = (value) => {
        if (value === 'BCX') {
            return {
                partyCode: 'BCX',
                partyGSTIN: '03BEZPS8372B1Z0',
                partyAddress: '#302. St.No. 8, S.C. Jamalpur, Ludhiana',
                partyPANNo: 'BEZPS8372B',
                partyEmail: 'balak.cargoxpress@gmail.com',
                partyName: 'Balak Cargo Xpress'
            }
        } else {
            return {
                partyCode: 'BE',
                partyGSTIN: '03CHDPS2678NIZT',
                partyAddress: '97 Transport Nagar, Ludhiana',
                partyPANNo: 'CHDPS2678N',
                partyEmail: 'balakexpress@gmail.com',
                partyName: 'Balak Express'
            }
        }
    };

    return (
        <ToggleContext.Provider value={{ toggle, toggleValue, data, getCompanyData }}>
            {children}
        </ToggleContext.Provider>
    );
};