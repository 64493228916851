import React, { useContext, useState } from 'react';
import { message, Modal } from 'antd';
import { ToggleContext } from '../Context/toggleContext';

const ConfirmModal = ({ isModalOpen, handleOk, handleCancel }) => {
    const { toggle, toggleValue, data } = useContext(ToggleContext);

    return (
        <Modal title={`Do you want to generate bill using ${data.partyName}`} open={isModalOpen} onOk={handleOk} onCancel={handleCancel}>
        </Modal>
    );
};

export default ConfirmModal;