import './App.css';
import React, { useState } from 'react';
import { Route, Routes } from 'react-router-dom';
import NavBar from './Components/navBar';
import NewInvoicePage from './Pages/newInvoice';
import AnalyticsPage from './Pages/analytics';
import PrevInvoicesPage from './Pages/prevInvoices';
import MainPage from './Pages/main';
import LoginPage from './Pages/loginPage'

import { ToggleProvider } from './Context/toggleContext';
import ViewInvoicePage from './Pages/viewInvoice';
// import DownloadInvoicePage from './Pages/downloadInvoice';

const App = () => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);

  if (!isAuthenticated) {
    return <LoginPage setIsAuthenticated={setIsAuthenticated} />;
  }

  return (
    <ToggleProvider>
      <Routes>
        {/* <Route path="/download-invoice" element={<DownloadInvoicePage />} /> */}
        <Route path="/new-invoice" element={<NewInvoicePage />} />
        <Route path="/edit-invoice" element={<NewInvoicePage />} />
        <Route path="/analytics" element={<AnalyticsPage />} />
        <Route path="/prev-invoices" element={<PrevInvoicesPage />} />
        <Route path="/view-invoice/:id" element={<ViewInvoicePage />} />
        <Route path="/main" element={<MainPage />} />
        <Route path="/" element={<MainPage />} />
      </Routes>
    </ToggleProvider>
  );
};

export default App;