import React, { useEffect } from 'react';
import { Button, Card, Form, Input } from 'antd';
import { useNavigate } from 'react-router-dom';
import supabase from '../db';
import './common.css';

const LoginPage = ({ setIsAuthenticated }) => {
    const navigate = useNavigate();

    useEffect(() => {
        // Check if user is already authenticated
        const isAuth = localStorage.getItem('isAuthenticated');
        if (isAuth) {
            setIsAuthenticated(true);
            navigate('/main');
        }
    }, [navigate, setIsAuthenticated]);

    const handleLogin = async (enteredUsername, enteredPassword) => {
        const { data, error } = await supabase.auth.signInWithPassword({
            email: enteredUsername,
            password: enteredPassword,
        });

        if (data?.user?.aud === "authenticated") {
            setIsAuthenticated(true);
            localStorage.setItem('isAuthenticated', 'true');
            navigate('/main');
        } else {
            alert('Invalid username or password');
            setIsAuthenticated(false);
            localStorage.removeItem('isAuthenticated');
        }
    };

    const onFinish = (values) => {
        handleLogin(values.username, values.password);
        console.log('Success:', values);
    };

    const onFinishFailed = (errorInfo) => {
        console.log('Failed:', errorInfo);
    };

    return (
        <div className="login-container">
            <Card title='Login' className="login-card">
                <Form
                    name="basic"
                    onFinish={onFinish}
                    onFinishFailed={onFinishFailed}
                    className="login-form"
                >
                    <Form.Item
                        label="Username"
                        name="username"
                        rules={[
                            {
                                required: true,
                                message: 'Please input your username!',
                            },
                        ]}
                    >
                        <Input />
                    </Form.Item>

                    <Form.Item
                        label="Password"
                        name="password"
                        rules={[
                            {
                                required: true,
                                message: 'Please input your password!',
                            },
                        ]}
                    >
                        <Input.Password />
                    </Form.Item>
                    <Form.Item>
                        <Button type="primary" htmlType="submit" className="login-submit-button">
                            Submit
                        </Button>
                    </Form.Item>
                </Form>
            </Card>
        </div>
    );
};

export default LoginPage;